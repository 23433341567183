/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable no-return-assign */
import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import clone from 'lodash/clone';
import classNames from 'classnames';
import { defaultValidation, initializeValidator } from 'helpers/validationHelper';
import cloneDeep from 'lodash/cloneDeep';
import FormCard from 'components/UtilityForm/Card';
import UtilitySelect from 'components/UtilityForm/UtilitySelect';
import { authService } from 'services/authService';
import { getPropertiesBankDetailsV2 } from 'services/surveyService';
import Checkbox from '../common/Checkbox';
import Numberbox from '../common/Numberbox';
import Radio from '../common/Radio';
import Textbox from '../common/Textbox';
import Textarea from '../common/Textarea';
import './CustomForm.scss';
import {
  connectFormDataWithTemplate,
  getAdditionalFields,
  getDefaultFields,
  getSectionById,
} from './helpers';
import { getFieldName } from '../../helpers/fieldHelper';
import Boolean from '../common/Boolean';
import { noValidateArr, spaceValidationArr } from './constants';

const CustomForm = ({
  formData,
  personalDetails,
  propertyDetails,
  pageChange,
  initPage,
  initializeProfile,
  sectionTypeChanged,
  submitCurrentSection,
  updatePropertySummary,
  match: {
    url,
    params: { step, propertyId },
  },
}) => {
  const { t } = useTranslation('form');
  const location = useLocation();

  const formDataSections = useMemo(
    () =>
      cloneDeep(
        formData.sections.filter(
          (section) =>
            initializeProfile ||
            ((personalDetails ? section.personalDetails : true) &&
              (propertyDetails ? section.propertyDetails : true))
        )
      ),
    [formData, personalDetails, propertyDetails, initializeProfile]
  );

  const initForm = useMemo(() => getDefaultFields(formDataSections), [formDataSections]);

  const additionalFieldsInit = useMemo(
    () => getAdditionalFields(formDataSections),
    [formDataSections]
  );

  const selectPropertyOption = {
    value: '-- Select Property --',
    label: '-- Select Property --',
    disabled: true,
  };

  const [form, setForm] = useState(initForm);
  const [validators] = useState(initializeValidator(formDataSections, t));
  const [currentSection] = useState(initPage);
  const [additionalFields, setAdditionalFields] = useState(additionalFieldsInit);
  const [editAvailable, setEditAvailable] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [propertiesOptions, setPropertiesOptions] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(selectPropertyOption);

  const formDataSectionsFull = useMemo(() =>
    formDataSections.map((section) => ({
      ...section,
      filled: validators[getFieldName(section.name)]?.allValid(),
    }))
  );

  // const filledItems = useMemo(
  //   () => formDataSectionsFull.filter((section) => section.filled).length
  // );

  const inputDisabled = () => !editAvailable && !initializeProfile;

  const getCurrentSection = () => {
    const section = formDataSections[currentSection - 1];
    if (section) {
      const sectionType = section.personalDetails
        ? 'personalDetails'
        : section.propertyDetails
        ? 'propertyDetails'
        : null;

      return sectionType;
    }

    return null;
  };

  const modifyPropertiesOptions = (options) =>
    options
      .filter((option) => option.property_id !== propertyId)
      .map((property) => ({ ...property, value: property.property_id, label: property.nickname }));

  const getPropertiesBankDetails = async () => {
    const response = await getPropertiesBankDetailsV2({
      ownerId: authService.currentUser.userId,
    });

    if (response.success) {
      const modifiedData = modifyPropertiesOptions(response.surveyBankDetails);
      setPropertiesOptions(modifiedData);
    }
  };

  useEffect(() => {
    getPropertiesBankDetails();
  }, []);

  useEffect(() => {
    if (!location.pathname.startsWith('/settings/personal-survey/')) {
      updatePropertySummary(true);
    } else {
      updatePropertySummary(false);
    }
  }, []);

  useEffect(() => {
    pageChange(currentSection);

    const section = getCurrentSection();
    if (section) {
      sectionTypeChanged(section);
    }
  }, [currentSection, pageChange, formDataSections]);

  const isValidDubaiIban = (ibanTxt) => {
    if (ibanTxt.includes(' ')) return false;

    // can not type if length is already 23 when starting with 'AE'
    return !ibanTxt.startsWith('AE') || ibanTxt.length >= 23;
  };

  const handleIbanValidation = (name, value, errMsgArr, index, sectionCamelName) => {
    const removeValidationArr = ['accountNo', 'swiftCode'];
    let newErrorMsgArr = [...errMsgArr];
    const ibanVal = name === 'iban' ? value : form?.bankAccountDetails?.iban;
    if ((name === 'iban' || removeValidationArr.includes(name)) && ibanVal?.startsWith('AE')) {
      const currentSectionObj = formDataSections[currentSection - 1];
      removeValidationArr.forEach((field) => {
        const idx = currentSectionObj?.fields.findIndex(
          (fieldObj) => getFieldName(fieldObj?.name) === field
        );
        const isSpaceAvailable = handleSpaceValidation(name, value);
        if (!isSpaceAvailable && idx === index && value.length > 0) newErrorMsgArr[idx] = '';
      });

      if (name === 'iban') {
        newErrorMsgArr[index] = isValidDubaiIban(value) ? '' : 'Please provide IBAN with 23 digits';
      }
    } else if (handleSpaceValidation(name, value)) {
      newErrorMsgArr[index] = 'Please provide valid details';
    }

    return newErrorMsgArr;
  };

  const handleChange = (e, sectionCamelName, arrIdx, index, fieldState) => {
    const { name, value, type, checked } = e.target;
    let newValue = value;
    if (type === 'checkbox') {
      newValue = form[sectionCamelName][name] || [];
      if (checked) {
        newValue.push(value);
      } else {
        const newIndex = newValue.indexOf(value);
        if (newIndex > -1) {
          newValue.splice(newIndex, 1);
        }
      }
    }

    if (arrIdx >= 0) {
      newValue = clone(form[sectionCamelName][name]);
      newValue[arrIdx] = value;
    }

    const newErrorMsgArr = [...errorMessages];

    if (!noValidateArr.includes(name) && newValue?.trim()?.length <= 0) {
      newErrorMsgArr[index] = 'This field is required!';
    } else if (handleSpaceValidation(name, newValue)) {
      newErrorMsgArr[index] = 'Please provide valid details';
    } else newErrorMsgArr[index] = '';

    // US based IBAN number validation based on entered swiftCodes
    const ibanIdx = getFormFieldIndex('iban');
    const ibanVal = form[sectionCamelName].iban;
    if (name !== 'currency') {
      if (!validateIbanBasedOnSwifCode(name, value, newErrorMsgArr)) {
        newErrorMsgArr[ibanIdx] = 'This field is required!';
      } else if (!handleSpaceValidation('iban', ibanVal)) {
        // if space is not available only then make it empty
        newErrorMsgArr[ibanIdx] = '';
      }
    }

    const errMsgArr =
      sectionCamelName === 'bankAccountDetails'
        ? [...handleIbanValidation(name, value, newErrorMsgArr, index)]
        : newErrorMsgArr;

    setErrorMessages(errMsgArr);

    let updatedValue = {
      ...form,
      [sectionCamelName]: {
        ...form[sectionCamelName],
        // can not type if iban number length is already 23 when starting with 'AE'
        [name]:
          newValue.length > 23 &&
          name === 'iban' &&
          (newValue.startsWith('AE') || value.startsWith('AE'))
            ? form[sectionCamelName][name]
            : newValue,
      },
    };
    if (name === 'iban' && !value?.startsWith('AE')) {
      if (form[sectionCamelName]?.currency === 'AED') updatedValue[sectionCamelName].currency = '';
    }
    if (name === 'iban' && value?.startsWith('AE')) {
      updatedValue[sectionCamelName].wouldYouLikeToReceivePayoutInAed = '';
    }

    setForm(updatedValue);
  };

  const addAdditionalField = (additionalField, section, field) => {
    const copy = clone(additionalFields);
    copy[section][field] = additionalField + 1;
    setAdditionalFields(copy);

    const newValue = clone(form[section][field]);
    newValue.push('');

    setForm({
      ...form,
      [section]: {
        ...form[section],
        [field]: newValue,
      },
    });
  };

  const handleSpecialValidations = (section, camelName, idx, errorMessagesArr) => {
    const iban = form[section]?.iban;
    if (camelName === 'iban') {
      const ibanTxt = form[section]?.[camelName]?.trim();
      if (!isValidDubaiIban(ibanTxt)) {
        const newErrorMessagesArr = [...errorMessagesArr];
        newErrorMessagesArr[idx] = 'Please provide IBAN with 23 digits';

        return newErrorMessagesArr;
      }
    } else if (['swiftCode', 'accountNo'].includes(camelName) && iban && iban.startsWith('AE')) {
      const newErrArr = [...errorMessagesArr];
      newErrArr[idx] = '';

      return [...newErrArr];
    }

    return errorMessagesArr;
  };

  const validateIbanBasedOnSwifCode = (name, value, errorMessageArr) => {
    const americanCode = 'BOFAUS';
    const { iban, swiftCode } = form.bankAccountDetails || {};
    const isAmericanSwiftCode =
      name === 'swiftCode' ? value.startsWith(americanCode) : swiftCode?.startsWith(americanCode);

    if (!iban && name === 'swiftCode' && !isAmericanSwiftCode) return false;
    if (iban && name === 'swiftCode' && isAmericanSwiftCode) return true;

    return !(
      (name === 'iban' && value.length < 1 && !isAmericanSwiftCode) ||
      (!isAmericanSwiftCode && !iban)
    );
  };

  const getFormFieldIndex = (fieldName) => {
    const currentSectionObj = formDataSections[currentSection - 1];
    const idx = currentSectionObj.fields.findIndex(
      (fieldObj) => getFieldName(fieldObj?.name) === fieldName
    );

    return idx;
  };

  const handleSpaceValidation = (field, value) => {
    const isSpaceAvailable = value.includes(' ') && spaceValidationArr.includes(field);

    return isSpaceAvailable;
  };

  const validBankDetails = (section) => {
    let isValidAll = true;
    if (section === 'bankAccountDetails') {
      const sectionByname = formDataSections[currentSection - 1];
      if (sectionByname) {
        let newErrorMessagesArr = Array(sectionByname?.fields?.length).fill('');
        const ibanIdx = getFormFieldIndex('iban');
        sectionByname?.fields?.forEach((field, idx) => {
          const camelName = getFieldName(field?.name);
          if (!noValidateArr.includes(camelName)) {
            if (form[section][camelName]?.trim()?.length < 1) {
              newErrorMessagesArr[idx] = 'This field is required!';
            }

            // US based IBAN number validation based on entered swiftCodes
            if (camelName === 'swiftCode') {
              const ibanVal = form[section].iban;

              newErrorMessagesArr[ibanIdx] =
                validateIbanBasedOnSwifCode(
                  camelName,
                  form[section][camelName],
                  newErrorMessagesArr
                ) && !ibanVal.includes(' ')
                  ? ''
                  : 'This field is required!';
            }

            newErrorMessagesArr = [
              ...handleSpecialValidations(section, camelName, idx, newErrorMessagesArr),
            ];
          }
          const isSpaceAvailable = handleSpaceValidation(camelName, form[section][camelName]);
          if (isSpaceAvailable) newErrorMessagesArr[idx] = 'Please provide valid details';
        });
        setErrorMessages(newErrorMessagesArr);

        return newErrorMessagesArr.every((message) => !message);
      }
    }

    return isValidAll;
  };

  const validatePersonalDetails = (section) => {
    let isValidAll = true;
    const sectionByname = formDataSections[currentSection - 1];
    if (sectionByname) {
      let newErrorMessagesArr = Array(sectionByname?.fields?.length).fill('');

      sectionByname?.fields?.forEach((field, idx) => {
        const camelName = getFieldName(field?.name);
        if (!noValidateArr.includes(camelName)) {
          if (form[section][camelName]?.trim()?.length < 1) {
            newErrorMessagesArr[idx] = 'This field is required!';
            isValidAll = false;
          }
        }
      });
      setErrorMessages(newErrorMessagesArr);
    }

    return isValidAll;
  };
  const onNextClick = async (section, nextStep) => {
    const currentSectionObj = formDataSections[currentSection - 1];
    const isSectionValid =
      section === 'personalDetails' ? validatePersonalDetails(section) : validBankDetails(section);

    const isEdit = !(selectedProperty.value === selectPropertyOption.value) || editAvailable;
    if (isSectionValid) {
      let response = await submitCurrentSection(
        connectFormDataWithTemplate(form, [currentSectionObj]),
        true,
        isEdit,
        formDataSectionsFull.every(({ filled }) => filled)
      );
    }
  };

  const checkIfRequired = (requiredIf, sectionId) => {
    const section = getSectionById(formDataSections, sectionId);
    if (section) {
      const field = section.fields.find((item) => item.id === requiredIf.id);
      if (field) {
        const formField = form?.[getFieldName?.(section?.name)]?.[getFieldName?.(field?.name)];

        if (field.type === 'textbox') {
          return requiredIf.hasValue ? formField?.length : !formField?.length;
        }

        if (formField === requiredIf.hasValue) {
          return true;
        }
      }
    }

    return false;
  };

  const checkIfIsVisible = (showIf, sectionId) => {
    const section = getSectionById(formDataSections, sectionId);
    if (section) {
      const field = section.fields.find((item) => item.id === showIf.id);
      if (field) {
        const formField = form[getFieldName(section.name)][getFieldName(field.name)];

        if (formField === showIf.hasValue) {
          return true;
        }
      }
    }

    return false;
  };

  const onSubmit = (event) => {
    event.preventDefault();
  };

  const handlePropertySelect = (e) => {
    const selectedEle = propertiesOptions.find(
      (property) => property.property_id === e.target.value
    );
    setSelectedProperty(selectedEle);

    // if property is selected - can not edit the fields
    setEditAvailable(false);
    const bankAccountDetails = cloneDeep(selectedEle.bankAccountDetails);
    setForm((prevData) => ({
      ...prevData,
      bankAccountDetails,
    }));
  };

  return (
    <FormCard>
      <form className="edit-profile-form" onSubmit={onSubmit} noValidate>
        {formDataSectionsFull.map((section, sectionIndex) => {
          let fieldNumber = 0;
          const sectionCamelName = getFieldName(section.name);
          const isCurrentSection = currentSection === sectionIndex + 1;
          validators[sectionCamelName]?.purgeFields();
          validators[`${sectionCamelName}Format`]?.purgeFields();

          return (
            <div key={`section-${section.id}`} className={isCurrentSection ? '' : 'hidden-section'}>
              <div className="section-header">
                <div>
                  <h3 className="section-name">{t(sectionCamelName)}</h3>
                  <p className="section-description">{t(section.description)}</p>
                </div>
                {!initializeProfile && selectedProperty.value === selectPropertyOption.value ? (
                  <div>
                    <button
                      type="button"
                      className="btn btn-edit"
                      onClick={() =>
                        setEditAvailable(selectedProperty.value === selectPropertyOption.value)
                      }
                      style={{ background: '#FFFFFF' }}
                    >
                      edit
                    </button>
                  </div>
                ) : null}
              </div>
              {section.bankData && propertiesOptions.length > 0 && (
                <div className="select-box-container">
                  <UtilitySelect
                    options={[selectPropertyOption, ...propertiesOptions]}
                    name="property-select"
                    selectedValue={selectedProperty?.value || ''}
                    onChange={(e) => handlePropertySelect(e)}
                    title="COPY BANK DETAILS FROM THE EXISTING PROPERTY"
                    disabled={false}
                    labelStyle={{ color: '#AD8C63' }}
                  />
                </div>
              )}
              {section.fields.map((field, index) => {
                const camelName = getFieldName(field.name);
                const fieldState = form?.[sectionCamelName]?.[camelName];
                const additionalField = additionalFields?.[sectionCamelName]?.[camelName];
                const {
                  validation,
                  requiredIf,
                  showIf,
                  customValidationMessage,
                  multipleFields,
                  customMultipleName,
                  name,
                  type,
                } = field;

                if (showIf && !checkIfIsVisible(showIf, section.id)) {
                  return null;
                }

                const formatRules = validation
                  ?.split('|')
                  .filter((x) => x !== 'required' && !requiredIf)
                  .join('|');

                const requiredRules = validation
                  ?.split('|')
                  .filter(
                    (val) =>
                      !(
                        val === 'required' &&
                        requiredIf &&
                        !checkIfRequired(requiredIf, section.id)
                      )
                  )
                  .filter((x) => x === 'required' || requiredIf)
                  .concat(multipleFields ? ['array'] : [])
                  .join('|');

                return (
                  <div key={section.id + name}>
                    {type === 'textbox' ? (
                      <>
                        {multipleFields ? (
                          <>
                            {Array.from(Array(additionalField)).map((_, i) => (
                              <Textbox
                                key={camelName + fieldNumber}
                                index={(fieldNumber += 1)}
                                fieldState={fieldState[i]}
                                name={camelName}
                                handleChange={(e) => handleChange(e, sectionCamelName, i, index)}
                                disabled={inputDisabled(section)}
                              />
                            ))}
                            <p
                              className="textbox-add-new"
                              onClick={() =>
                                addAdditionalField(additionalField, sectionCamelName, camelName)
                              }
                            >
                              {`+ ${customMultipleName || name}`}
                            </p>
                          </>
                        ) : (
                          <Textbox
                            index={(fieldNumber += 1)}
                            fieldState={fieldState}
                            name={camelName}
                            handleChange={(e) => handleChange(e, sectionCamelName, -1, index)}
                            disabled={inputDisabled(section)}
                            errorMessage={errorMessages?.[index] || ''}
                          />
                        )}
                      </>
                    ) : type === 'textarea' ? (
                      <Textarea
                        index={(fieldNumber += 1)}
                        fieldState={fieldState}
                        field={field}
                        name={camelName}
                        handleChange={(e) => handleChange(e, sectionCamelName, -1, index)}
                        disabled={inputDisabled(section)}
                        errorMessage={errorMessages?.[index] || ''}
                      />
                    ) : type === 'radio' ? (
                      <>
                        {camelName === 'currency' && (
                          <Radio
                            index={(fieldNumber += 1)}
                            fieldState={fieldState}
                            field={field}
                            name={camelName}
                            handleChange={(e) =>
                              handleChange(e, sectionCamelName, -1, index, fieldState)
                            }
                            disabled={inputDisabled(section)}
                            form={form}
                            errorMessage={errorMessages?.[index] || ''}
                          />
                        )}
                        {form?.bankAccountDetails?.iban &&
                          form?.bankAccountDetails?.currency &&
                          !form?.bankAccountDetails?.iban?.startsWith('AE') &&
                          camelName === 'wouldYouLikeToReceivePayoutInAed' && (
                            <Radio
                              index={(fieldNumber += 1)}
                              fieldState={fieldState}
                              field={field}
                              name={camelName}
                              handleChange={(e) =>
                                handleChange(e, sectionCamelName, -1, index, fieldState)
                              }
                              disabled={inputDisabled(section)}
                              form={form}
                              errorMessage={errorMessages?.[index] || ''}
                            />
                          )}
                      </>
                    ) : type === 'checkbox' ? (
                      <Checkbox
                        index={(fieldNumber += 1)}
                        fieldState={fieldState}
                        field={field}
                        name={camelName}
                        handleChange={(e) => handleChange(e, sectionCamelName)}
                        disabled={inputDisabled(section)}
                      />
                    ) : type === 'number' ? (
                      <Numberbox
                        index={(fieldNumber += 1)}
                        fieldState={fieldState}
                        field={field}
                        name={camelName}
                        handleChange={(e) => handleChange(e, sectionCamelName)}
                        disabled={inputDisabled(section)}
                      />
                    ) : type === 'boolean' ? (
                      <>
                        {(!section.fields[index - 1] ||
                          section.fields[index - 1]?.type !== 'boolean') && (
                          <div className="bool-header">
                            <div className="bool-spacer" />
                            <div className="bool-label">{t('noyes')}</div>
                          </div>
                        )}
                        <Boolean
                          index={(fieldNumber += 1)}
                          fieldState={fieldState}
                          field={field}
                          name={camelName}
                          handleChange={(e) => handleChange(e, sectionCamelName)}
                          disabled={inputDisabled(section)}
                        />
                      </>
                    ) : null}
                    {isCurrentSection && formatRules?.length > 0
                      ? defaultValidation(
                          validators[`${sectionCamelName}Format`],
                          fieldState,
                          camelName,
                          formatRules,
                          {
                            ...(customValidationMessage || {}),
                          },
                          t
                        )
                      : null}
                    {requiredRules?.length > 0
                      ? defaultValidation(
                          validators[`${sectionCamelName}`],
                          fieldState,
                          camelName,
                          requiredRules,
                          null,
                          t
                        )
                      : null}
                  </div>
                );
              })}
              <div className="buttons">
                <button
                  type="submit"
                  className={classNames('btn btn-next', {
                    finish: true,
                  })}
                  onClick={() => onNextClick(sectionCamelName)}
                >
                  {t('save')}
                </button>
              </div>
            </div>
          );
        })}
      </form>
    </FormCard>
  );
};

export default CustomForm;

// const validPersonal = (section) =>
//   (personalDetails &&
//     validators[`${section}Format`].allValid() &&
//     validators[`${section}`].allValid()) ||
//   !editAvailable;

// const validProperty = (section) =>
//   (propertyDetails && validators[`${section}Format`].allValid()) || !editAvailable;
