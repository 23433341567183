import { APP_VERSION } from 'helpers/const';
import Axios from 'axios';
import environment from 'environments/environment';
import { authService } from './authService';

export default class AppService {
  constructor() {
    this.currentVersions = this.getVerion();
  }

  getVerion() {
    const jsonVersion = localStorage.getItem(APP_VERSION);
    if (jsonVersion) {
      return JSON.parse(jsonVersion);
    }

    return null;
  }

  setVersion(version) {
    localStorage.setItem(APP_VERSION, JSON.stringify(version));
    this.currentVersions = version;
  }

  async getLatestAppVersion() {
    try {
      const { data: response } = await Axios.get(`${environment.ownerApi}/version_log`);

      if (!response.success) {
        throw new Error('An error occured');
      }
      const versionData = {
        ...response.latest_version_log,
      };

      this.setVersion(versionData);

      return versionData;
    } catch (error) {
      authService.logoutProceed();

      throw new Error(error);
    }
  }

  // run side by side in postman to add new released app version

  async setLatestAppVersion(versionData) {
    // const payload = {
    //   android_older_version: 12,
    //   android_newer_version: 2,
    //   ios_older_version: 3,
    //   ios_newer_version: 4,
    // };
    try {
      const response = await Axios.post(`${environment.ownerApi}/version_log`, versionData);
      if (response.success) {
        this.setVersion(versionData);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
}

export const appService = new AppService();
