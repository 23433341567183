export const noValidateArr = [
  'accountRouteCode',
  'accountSortCode',
  'secondaryEmailAddress',
  'secondaryPhoneNumber',
  'address',
  'wouldYouLikeToReceivePayoutInAed',
];

export const spaceValidationArr = [
  'accountRouteCode',
  'accountSortCode',
  'swiftCode',
  'accountNo',
  'iban',
];
