import Axios from 'axios';
import _ from 'lodash';
import environment from '../environments/environment';

function modifyURL(baseURL) {
  const parts = baseURL.split('/');
  const index = _.findIndex(parts, (part) => part === 'api');

  if (index !== -1 && index < parts.length - 1) {
    parts.splice(index + 1, 0, 'v2');
    const modifiedURL = parts.join('/');

    return modifiedURL;
  }
}

export const getSurvey = (props) =>
  new Promise((resolve) => {
    getSurveyGet(props)
      .then((item) => {
        if (item.data?.surveyData) {
          resolve({
            surveyData: item.data.surveyData,
            property: item.data.property,
            completedSurveys: item.data.completedSurveys,
          });
        } else {
          resolve({
            surveyData: { sections: [] },
            property: {},
            success: false,
          });
        }
      })
      .catch(() => {
        resolve({
          surveyData: { sections: [] },
          property: {},
          success: false,
        });
      });
  });

export const getSurveyV2 = (props) =>
  new Promise((resolve) => {
    getSurveyGetV2(props)
      .then((item) => {
        if (item.data?.surveyData) {
          resolve({
            surveyData: item.data.surveyData,
            property: item.data.property,
            completedSurveys: item.data.completedSurveys,
          });
        } else {
          resolve({
            surveyData: { sections: [] },
            property: {},
            success: false,
          });
        }
      })
      .catch(() => {
        resolve({
          surveyData: { sections: [] },
          property: {},
          success: false,
        });
      });
  });

export const getSurveySummary = (props) =>
  new Promise((resolve) => {
    getSurveySummaryGet(props)
      .then((item) => {
        if (item.data) {
          resolve({
            property: item.data.property,
            propertyBankAccount: item.data.property_bank_account,
            details: item.data.details,
          });
        } else {
          resolve({
            property: {},
            success: false,
          });
        }
      })
      .catch(() => {
        resolve({
          property: {},
          success: false,
        });
      });
  });

export const saveSurvey = (props) =>
  new Promise((resolve) => {
    surveyPost(props)
      .then((item) => {
        if (item.data.success) {
          resolve(item.data.surveyData);
        } else {
          resolve(null);
        }
      })
      .catch(() => {
        resolve(null);
      });
  });

export const saveSurveyV2 = (props) =>
  new Promise((resolve) => {
    surveyPostV2(props)
      .then((item) => {
        if (item.data.success) {
          resolve(item.data.surveyData);
        } else {
          resolve(null);
        }
      })
      .catch(() => {
        resolve(null);
      });
  });

const getSurveyGet = ({ ownerId, propertyId }) =>
  Axios.get(`${environment.ownerApi}/owner_survey/`, {
    params: { ownerId, propertyId },
  });

export const getSurveyGetV2 = ({ ownerId, propertyId }) => {
  const modifiedURL = modifyURL(environment.ownerApi);

  return Axios.get(`${modifiedURL}/owner_survey/`, {
    params: { ownerId, propertyId: propertyId ?? 'null' },
  });
};

const getSurveySummaryGet = ({ propertyId }) =>
  Axios.get(`${environment.ownerApi}/property/`, {
    params: { id: propertyId },
  });

const surveyPost = ({ ownerId, propertyId, sections, completed }) =>
  Axios.post(
    `${environment.ownerApi}/owner_survey/?ownerId=${ownerId}${
      propertyId ? `&propertyId=${propertyId}` : ``
    }`,
    {
      sections,
      completed,
    }
  );

const surveyPostV2 = ({ ownerId, propertyId, sections, completed }) => {
  const modifiedURL = modifyURL(environment.ownerApi);

  return Axios.post(
    `${modifiedURL}/owner_survey/?ownerId=${ownerId}${
      propertyId ? `&propertyId=${propertyId}` : ``
    }`,
    {
      sections,
      completed,
    }
  );
};

export const getPropertiesBankDetailsV2 = (props) =>
  new Promise((resolve) => {
    getPropertiesBankDetails(props)
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          resolve({ success: false, surveyBankDetails: [] });
        }
      })
      .catch(() => {
        resolve({ success: false, surveyBankDetails: [] });
      });
  });

const getPropertiesBankDetails = ({ ownerId }) => {
  const modifiedURL = modifyURL(environment.ownerApi);

  return Axios.get(`${modifiedURL}/owner_bank_details/`, {
    params: { ownerId },
  });
};
