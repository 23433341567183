import React from 'react';
import _ from 'lodash';

const UtilitySelect = ({
  labelStyle = {},
  options,
  title,
  name,
  selectedValue,
  onChange,
  disabled = false,
}) => {
  if (!name) return <></>;

  return (
    <div className="form-group">
      <label style={labelStyle} htmlFor={name}>
        {title}
      </label>
      <select
        name={name}
        className="form-control"
        id={name}
        onChange={onChange}
        value={selectedValue}
        disabled={disabled}
      >
        {_.isArray(options) && !_.isEmpty(options) ? (
          options.map(({ label, value, ...item }) => (
            <option disabled={Boolean(item?.disabled)} value={value} key={value}>
              {label}
            </option>
          ))
        ) : (
          <option value="">Select</option>
        )}
      </select>
    </div>
  );
};

export default UtilitySelect;
