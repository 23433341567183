import React, { useEffect, useState } from 'react';
import { AppVersion } from '@awesome-cordova-plugins/app-version';
import Modal from 'components/common/Modal/Modal';
import './AppVersionCheck.scss';
import environment from 'environments/environment';
import { appService } from 'services/appService';

// cordova plugin add cordova-plugin-app-version - add package in cordova

const AppVersionCheck = () => {
  const [updateNeeded, setUpdateNeeded] = useState(false);
  const [isOpen] = useState(true);

  useEffect(() => {
    const checkForUpdates = async () => {
      try {
        const installedAppVersion = await AppVersion.getVersionNumber();
        const versionResponse = await appService.getLatestAppVersion();
        if (
          window.cordova &&
          ((window.cordova.platformId === 'ios' &&
            installedAppVersion < versionResponse.ios_newer_version) ||
            (window.cordova.platformId === 'android' &&
              installedAppVersion < versionResponse.android_newer_version))
        ) {
          setUpdateNeeded(true);
        }
      } catch (error) {
        console.error('Error checking app version:', error);
      }
    };

    const onDeviceReady = () => {
      checkForUpdates();
    };

    const onResume = () => {
      checkForUpdates();
    };

    if (window.cordova) {
      document.addEventListener('deviceready', onDeviceReady, false);
      document.addEventListener('resume', onResume, false);
    }

    return () => {
      if (window.cordova) {
        document.removeEventListener('deviceready', onDeviceReady, false);
        document.removeEventListener('resume', onResume, false);
      }
    };
  }, []);

  const updateApp = () => {
    if (window.cordova && window.cordova.platformId === 'ios') {
      window.location.href = `https://apps.apple.com/app/${environment.appStoreId}`;
    } else {
      // package name from config.xml - id
      window.location.href = `https://play.google.com/store/apps/details?id=${environment.androidPackageName}`;
    }
  };

  return (
    <>
      {updateNeeded && typeof window.cordova !== 'undefined' && (
        <Modal isOpen={isOpen} onClose={() => {}}>
          <h3>A new version of the app is available. Please update for the best experience.</h3>
          <button className="btn" type="button" onClick={() => updateApp()}>
            Ok
          </button>
        </Modal>
      )}
    </>
  );
};

export default AppVersionCheck;
